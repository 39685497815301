/* .button {
	transition: 0.3s;
}
.button:hover {
	transform: scale(1.2);
} */

/* .button:hover {
animation: bounce 1s;
} */

/* @keyframes bounce {
	0%, 20%, 60%, 100% {
		transform: translateY(0);
		transform: translateY(0);
	}

	40% {
		transform: translateY(-5px);
		transform: translateY(-5px);
	}

	80% {
		transform: translateY(-2px);
		transform: translateY(-2px);
	}
} */

.links {
	transition: 0.3s;
}

.links:hover, .links:active .links:focus {
	transform: scale(1.12);
}
