@font-face {
    font-family: 'Medium';
    src: local('Medium'),
        url('./assets/fonts/TTNormsPro/EOT/TT_Norms_Pro_Regular.eot') format('eot'),
        url('./assets/fonts/TTNormsPro/TTF/TT\ Norms\ Pro\ Regular.ttf') format('ttf'),
        url('./assets/fonts/TTNormsPro/WOFF/TT_Norms_Pro_Regular.woff') format('woff'),
        url('./assets/fonts/TTNormsPro/WOFF2/TT_Norms_Pro_Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Regular';
    src: local('Regular'),
        url('./assets/fonts/TTNormsPro/EOT/TT_Norms_Pro_Regular.eot') format('eot'),
        url('./assets/fonts/TTNormsPro/TTF/TT\ Norms\ Pro\ Regular.ttf') format('ttf'),
        url('./assets/fonts/TTNormsPro/WOFF/TT_Norms_Pro_Regular.woff') format('woff'),
        url('./assets/fonts/TTNormsPro/WOFF2/TT_Norms_Pro_Regular.woff2') format('woff2');
}